import { Modal } from "antd";

const ProviderDetailModal = ({ data, showModal, setShowModal }) => {
  return (
    <>
      <Modal
        title="Provider Details"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={<></>}
      >
        <p>
          Balance: <b>{data?.balance}</b>
        </p>
        <p>
          Total Balance: <b>{data?.totalBalance}</b>
        </p>
      </Modal>
    </>
  );
};

export default ProviderDetailModal;
