import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Select, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useGetLevelTwoProvidersQuery, usePutUserProviderMutation } from '../../../redux/Api/adminApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'antd/es/form/Form';
import { showEditModalClose } from '../../../redux/slice/healthSlice';
import { usePutUserMutation } from '../../../redux/Api/adminApi';

const UserEditModal = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const { isModalEdit, editRecord } = useSelector((state) => state.health);
  const [putUser, response] = usePutUserMutation();

  const [form] = useForm();

  useEffect(() => {
    if (editRecord) {
      form.setFieldsValue({
        name: editRecord.name,
      });
    }
  }, [editRecord, form]);

  const onFinish = async (value) => {
    const fd = new FormData();
    fd.append('name', value.name);
    if (file) fd.append('file', file);
    const data = {
      id: editRecord._id,
      type: fd,
    };
    putUser(data);
  };
  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
      dispatch(showEditModalClose());
    } else if (response && response.error) {
      toast.error(response.error.data.message);
    }
  }, [response]);

  const handleCancel = () => {
    dispatch(showEditModalClose());
  };

  return (
    <>
      <Modal open={isModalEdit} title='Edit Provider' onCancel={handleCancel} footer={() => <></>}>
        <Form form={form} initialValues={{ name: editRecord?.name }} layout='vertical' onFinish={onFinish}>
          <Form.Item label='Name' name='name'>
            <Input />
          </Form.Item>

          <Form.Item label='Upload Image'>
            <Input type='file' onChange={(e) => setFile(e.target.files[0])} />
          </Form.Item>
          <Button type='primary' htmlType='submit' className='gred-button'>
            Edit User
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default UserEditModal;
