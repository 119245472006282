import React, { useEffect } from "react";
import { Button, Table } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import MainLayout from "../../../component/MainLayout";
import {useDelQualificationMutation,useGetQualificationQuery} from "../../../redux/Api/adminApi";
import {QuqlificationAddModal,QuqlificationEditModal} from "./qualificationModal";
import {showAddModalOpen,showEditModalOpen} from "../../../redux/slice/healthSlice";
import { toast } from "react-toastify";


const Qualification = () => {
  const { data,isLoading } = useGetQualificationQuery();
  const [delQualification,response] = useDelQualificationMutation();
  const dispatch = useDispatch();

  const columns = [
    {
      key: "1",
      title: "Id",
      dataIndex: "_id",
    },
    {
      key: "2",
      title: "Name",
      dataIndex: "name",
    },
    // {
    //   key: "3",
    //   title: "Status",
    //   dataIndex: "status",
    // },
    // {
    //   key: "4",
    //   title: "Type",
    //   dataIndex: "type",
    // },
    {
      key: "5",
      title: "Action",
      render: (record) => {
        return (
          <>
            <EditOutlined
              onClick={() => dispatch(showEditModalOpen(record))}
              style={{ color: "green", marginLeft: "20px" }}
            />
            <DeleteOutlined
              onClick={() => delQualification(record._id)}
              style={{ color: "red", marginLeft: "20px" }}
            />
          </>
        );
      },
    },
  ];
  useEffect(() => {
    if(response && response.data){
      toast.success( response.data.message)
    }
   },[response])

  return (
    <>
      <MainLayout>
        <div className="table">
          <Button
            className="gred-button"
            onClick={() => dispatch(showAddModalOpen())}
           
          >
            Add
          </Button>
          <Table
            dataSource={data && data.list}
            columns={columns}
            scroll={{ y: 500, x: 1000 }}
            bordered
            loading={isLoading}
          />
        </div>
        <QuqlificationEditModal />
        <QuqlificationAddModal />
      </MainLayout>
    </>
  );
};

export default Qualification;
