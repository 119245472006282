import { Table } from "antd";
import React from "react";
import MainLayout from "../../component/MainLayout";
import { useGetBookingQuery } from "../../redux/Api/adminApi";
import { useSearch } from "../../hooks/useSearch";
import SearchBar from "../../component/SearchBar";

const Ongoing = () => {
  const { data, isLoading } = useGetBookingQuery(4);

  const [ongoingBookings, setSearch] = useSearch(data);

  const columns = [
    {
      key: "1",
      title: "Id",
      dataIndex: "_id",
    },
    {
      key: "2",
      title: "Doctor Name",
      render: (record) => record.doctorId && record.doctorId.name,
    },
    {
      key: "3",
      title: "User Name",
      render: (record) => record.userId.name,
    },

    {
      key: "4",
      title: "Description",
      dataIndex: "description",
    },

    {
      key: "5",
      title: "Booking Date",
      render: (record) =>
        new Date(record.selectedDateTime).toLocaleDateString(),
    },
    {
      key: "6",
      title: "Booking Time",
      dataIndex: "bookingTime",
    },
  ];

  return (
    <MainLayout>
      <div className="search-bar">
        <SearchBar setSearch={setSearch} />
      </div>

      <div className="table">
        <Table
          dataSource={ongoingBookings}
          columns={columns}
          scroll={{ y: 400, x: 1000 }}
          bordered
          loading={isLoading}
        />
      </div>
    </MainLayout>
  );
};

export default Ongoing;
