import { Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import {showAddModalClose} from "../../../redux/slice/healthSlice"
import {showEditModalClose} from "../../../redux/slice/healthSlice"
import {usePostChiropractorMutation,usePostLifestyleMutation,usePostSkillMutation,usePostTherapyMutation,usePutChiropractorMutation,usePutLifestyleMutation,usePutSkillMutation, usePutTherapyMutation} from "../../../redux/Api/adminApi"
import { toast } from 'react-toastify'


export const LifestyleAddModal = () => {
  const [skillAdd, setSkillAdd] = useState();

  const { isModalAdd } = useSelector((state) => state.health);
  const dispatch = useDispatch();

  const [postHealth, response] = usePostLifestyleMutation();

  const handleOk = () => {
    if (skillAdd === undefined) {
      return toast.error('please fill the data');
    }
    console.log(skillAdd,"skillAdd")
    let formData = new FormData();
    formData.append('name', skillAdd.name);
    formData.append('image', skillAdd.image);
    postHealth(formData);
    dispatch(showAddModalClose());
    // postHealth(skillAdd);
    // dispatch(showAddModalClose());
  };

  const handleChange = (e) => {
   
    const { name, value, files } = e.target; // 'files' instead of 'file' for file inputs
    if (name === "image") {
        setSkillAdd({ ...skillAdd, [name]: files[0] }); // Handling single file upload
    } else {
        setSkillAdd({ ...skillAdd, [name]: value });
    }
};

  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
    }
  }, [response]);

  return (
    <div>
      <Modal
        title='Add'
        open={isModalAdd}
        onOk={handleOk}
        onCancel={() => dispatch(showAddModalClose())}
        okButtonProps={{ style: { background: 'var(--gradient)' } }}
        okText='Add'
      >
        <div className='modal-input'>
          <Input placeholder='Enter Name' onChange={(e) => handleChange(e)} name='name' />
        </div>
        <div className='modal-input'>
          <Input type='file' accept='image/*' onChange={(e) => handleChange(e)} name="image" />
        </div>
        {/*
        <div className='modal-input'>
          <Input placeholder='Enter Status' onChange={(e) => handelChange(e)} name='status' />
        </div> */}
      </Modal>
    </div>
  );
}


export const  LifestyleEditModal = () => {
  const [skillEdit, setSkillEdit] = useState();
  const { isModalEdit, editRecord } = useSelector((state) => state.health);
  const dispatch = useDispatch();
  const [putSkill, response] = usePutLifestyleMutation();


  useEffect(() => {
    if (editRecord) {
      setSkillEdit({ name: editRecord.name, cost: editRecord.cost, status: editRecord.status });
    }
  }, [editRecord]);

  const handleOk = () => {
    let formData = new FormData();
    formData.append('name', skillEdit.name);
    formData.append('image', skillEdit.image);
    let data = {
      id: editRecord._id,
      type: formData,
    };
    if (skillEdit === undefined) {
      return toast.error('please fill the data');
    }
   
    putSkill(data);
    dispatch(showEditModalClose());
  };
  const handleChange = (e) => {
   
    const { name, value, files } = e.target; // 'files' instead of 'file' for file inputs
    if (name === "image") {
        setSkillEdit({ ...skillEdit, [name]: files[0] }); // Handling single file upload
    } else {
        setSkillEdit({ ...skillEdit, [name]: value });
    }
};

  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
    }
  }, [response]);


  const previewImage = skillEdit?.image
    ? URL.createObjectURL(skillEdit.image) // If an image is selected, show preview
    : editRecord?.image // If no new image, show the old image from the editRecord
    ? editRecord.serviceimage
    : null;

  return (
    <div>
      <Modal
        title='Edit'
        open={isModalEdit}
        onOk={handleOk}
        onCancel={() => dispatch(showEditModalClose())}
        okButtonProps={{ style: { background: 'var(--gradient)' } }}
        okText='Edit'
      >
        <div className='modal-input'>
          <Input placeholder='Enter Name' onChange={(e) => handleChange(e)} name='name' value={skillEdit?.name || ''} />
        </div>
        <div className='modal-input'>
          {/* <Input
            type='number'
            placeholder='Enter Cost'
            onChange={(e) => handelChange(e)}
            name='cost'
            value={skillEdit?.cost || 0}
          /> */}
          <Input type='file' accept='image/*' onChange={(e) => handleChange(e)} name="image" />
       
        </div>

        {/* <div className='modal-input'>
          <Input placeholder='Enter Type' onChange={(e) => handelChange(e)} name='type' value={skillEdit?.type || ''} />
        </div>
        <div className='modal-input'>
          <Input
            placeholder='Enter Status'
            onChange={(e) => handelChange(e)}
            name='status'
            value={skillEdit?.status || ''}
          />
        </div> */}

<div className="image-preview-box" style={{ marginTop: "20px" }}>
          {previewImage ? (
            <img
              src={previewImage}
              alt="Preview"
              width="100"
              height="100"
              style={{ objectFit: "cover", borderRadius: "8px" }}
            />
          ) : (
            <p>No image selected</p>
          )}
        </div>
      </Modal>
    </div>
  );
  }


