import { Input } from "antd";
import React from "react";

const SearchBar = ({ setSearch }) => {
  const { Search } = Input;
  const onSearch = (value, _e, info) => setSearch(value);

  return (
    <Search
      placeholder="Search"
      onSearch={onSearch}
      style={{
        width: 200,
      }}
    />
  );
};

export default SearchBar;
