import { useMemo, useState } from "react";

const useSearch = (data) => {
  const [search, setSearch] = useState("");

  const finalData = useMemo(() => {
    if (!data || !data?.list) return [];
    if (search) {
      const regex = new RegExp(search, "i");

      return (
        data &&
        data.list.filter((item) => {
          return (
            regex.test(item?._id || "") ||
            regex.test(item?.doctorId?.name || "") ||
            regex.test(item?.userId?.name || "")
          );
        })
      );
    } else {
      return data.list;
    }
  }, [search, data]);

  return [finalData, setSearch];
};

export { useSearch };
