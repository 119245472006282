import { Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showAddModalClose } from '../../../redux/slice/healthSlice';
import { showEditModalClose } from '../../../redux/slice/healthSlice';
import { usePostQualificationMutation, usePutQualificationMutation } from '../../../redux/Api/adminApi';
import { toast } from 'react-toastify';

export const QuqlificationAddModal = () => {
  const [qualAdd, setqualAdd] = useState();

  const { isModalAdd } = useSelector((state) => state.health);
  const [postQualification, response] = usePostQualificationMutation();

  const dispatch = useDispatch();

  const handleOk = () => {
    let data = { name: qualAdd };
    if (qualAdd === undefined) {
      return toast.error('please fill the data');
    }
    postQualification(data);
    dispatch(showAddModalClose());
  };
  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
    }
  }, [response]);

  return (
    <div>
      <Modal
        title='Add'
        open={isModalAdd}
        onOk={handleOk}
        onCancel={() => dispatch(showAddModalClose())}
        okButtonProps={{ style: { background: 'var(--gradient)' } }}
        okText='Add'
      >
        <Input placeholder='Enter Name' onChange={(e) => setqualAdd(e.target.value)} />
      </Modal>
    </div>
  );
};

export const QuqlificationEditModal = () => {
  const [qualEdit, setQualEdit] = useState();

  const { isModalEdit, editRecord } = useSelector((state) => state.health);
  console.log(editRecord,"editRecord")
  const dispatch = useDispatch();
  const [putQualification, response] = usePutQualificationMutation();

  const handelChange = (e) => {
    let { name, value } = e.target;
    setQualEdit({ ...qualEdit, [name]: value });
  };

  useEffect(() => {
    if (editRecord) {
      setQualEdit({ name: editRecord.name, type: editRecord.type, status: editRecord.status });
    }
  }, [editRecord]);

  const handleOk = () => {
    console.log(qualEdit,"qualEdit")
    let data = {
      id: editRecord._id,
      name: qualEdit?.name,
    };
    if (qualEdit === undefined) {
      return toast.error('please fill the data');
    }
    console.log(data,"data")
    putQualification(data);
    dispatch(showEditModalClose());
  };
  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
    }
  }, [response]);

  return (
    <div>
      <Modal
        title='Edit'
        open={isModalEdit}
        onOk={handleOk}
        onCancel={() => dispatch(showEditModalClose())}
        okButtonProps={{ style: { background: 'var(--gradient)' } }}
        okText='Edit'
      >
        <Input placeholder='Enter Name' name="name" value={qualEdit?.name} onChange={(e) => handelChange(e)}/>
      </Modal>
    </div>
  );
};
