import { Table } from "antd";
import React, { useMemo, useState } from "react";
import MainLayout from "../../component/MainLayout";
import { useGetTransactionQuery } from "../../redux/Api/adminApi";
import SearchBar from "../../component/SearchBar";

const Transections = () => {
  const { data, isLoading } = useGetTransactionQuery();

  const [search, setSearch] = useState("");

  const finalData = useMemo(() => {
    if (!data || !data?.data) return [];
    if (search) {
      const regex = new RegExp(search, "i");

      return data?.data?.filter((item) => regex.test(item?.bookingId || ""));
    } else {
      return data.data;
    }
  }, [search, data]);

  const columns = [
    {
      key: "1",
      title: "Date",
      render: (record) => new Date(record.createdAt).toLocaleDateString(),
    },
    {
      key: "2",
      title: "Amount",
      dataIndex: "amount",
    },
    {
      key: "3",
      title: "Booking Id",
      dataIndex: "bookingId",
    },
    {
      key: "4",
      title: "Payment-type",
      // dataIndex: "paymentType",
      render: (record) =>
        record.paymentType.slice(0, 1).toUpperCase() +
        record.paymentType.slice(1),
    },

    {
      key: "5",
      title: "Status",
      render: (record) => {
        return record.status === "succeeded" ? (
          <p style={{ color: "green", marginLeft: "20px" }}>Success</p>
        ) : (
          <p style={{ color: "red", marginLeft: "20px" }}>Pending </p>
        );
      },
    },
  ];

  return (
    <>
      <MainLayout>
        <div className="search-bar">
          <SearchBar setSearch={setSearch} />
        </div>
        <div className="table">
          <Table
            dataSource={finalData}
            columns={columns}
            scroll={{ y: 400, x: 1000 }}
            bordered
            loading={isLoading}
          />
        </div>
      </MainLayout>
    </>
  );
};

export default Transections;
