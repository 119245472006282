import React, { useEffect } from 'react';

import { Table, Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import MainLayout from '../../../component/MainLayout';
import { showAddModalOpen, showEditModalOpen } from '../../../redux/slice/healthSlice';

import { ChronicAddModal, ChronicEditModal } from './ChronicModal';
import { useDeleteChronicDiseaseMutation, useGetChronicDiseaseQuery } from '../../../redux/Api/adminApi';

const Chronic = () => {
  const { data, isLoading } = useGetChronicDiseaseQuery();
  const [deleteChronic, response] = useDeleteChronicDiseaseMutation();
  const dispatch = useDispatch();

  const columns = [
    {
      key: '1',
      title: 'Id',
      dataIndex: '_id',
    },
    {
      key: '2',
      title: 'Name',
      dataIndex: 'name',
    },
    {
      key: '3',
      title: 'Cost',
      dataIndex: 'cost',
    },
    {
      key: '4',
      title: 'Action',
      render: (record) => {
        return (
          <>
            <EditOutlined
              onClick={() => dispatch(showEditModalOpen(record))}
              style={{ color: 'green', marginLeft: '20px' }}
            />
            <DeleteOutlined onClick={() => deleteChronic(record._id)} style={{ color: 'red', marginLeft: '20px' }} />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
    }
  }, [response]);

  return (
    <MainLayout>
      <div className='table'>
        <Button className='gred-button' onClick={() => dispatch(showAddModalOpen())}>
          Add
        </Button>
        <Table
          dataSource={data && data.list}
          columns={columns}
          scroll={{ y: 400, x: 1000 }}
          bordered
          loading={isLoading}
        />
      </div>
      <ChronicAddModal />
      <ChronicEditModal />
    </MainLayout>
  );
};

export default Chronic;
