import { Modal } from "antd";

const UserDetailModal = ({ userData, showModal, setShowModal }) => {
  console.log(userData);

  return (
    <>
      <Modal
        title="Basic Modal"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={<></>}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    </>
  );
};

export default UserDetailModal;
