import { Button, Image, Table } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../../component/MainLayout';
import { AiFillEdit } from 'react-icons/ai';
import { IoMdEye } from 'react-icons/io';
import { useGetUserQuery } from '../../../redux/Api/adminApi';
import { useDispatch } from 'react-redux';
import { showEditModalOpen } from '../../../redux/slice/healthSlice';
import UserDetailModal from '../../../component/Modals/UserDetailModal';
import UserEditModal from './UserEditModal';

const ManageUser = () => {
  const { data, isLoading } = useGetUserQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState({});

  const columns = [
    {
      key: '1',
      title: 'Id',
      dataIndex: 'id',
    },
    {
      key: '2',
      title: 'User Name',
      dataIndex: 'name',
    },
    {
      key: '3',
      title: 'Email',
      dataIndex: 'email',
    },
    {
      key: '4',
      title: 'Image Url',
      dataIndex: 'imageUrl',
      render: (record) => (record ? <Image width={50} height={50} src={record} fallback='Uers-image' /> : 'No Image'),
    },
    {
      key: '5',
      title: 'Action',
      render: (record) => (
        <>
          <AiFillEdit className='action-icon' onClick={() => handelEdit(record)} style={{ color: 'green' }} />
          {/* <IoMdEye
            onClick={() => handleView(record)}
            className="action-icon"
            style={{ color: "#1677ff" }}
          /> */}
        </>
      ),
    },
  ];

  const handleView = (record) => {
    setShowModal(true);
    setUserData(record);
  };

  const handelEdit = (record) => {
    dispatch(showEditModalOpen(record));
    // navigate("/user/user-edit-form");
  };

  return (
    <>
      <MainLayout>
        <UserDetailModal userData={userData} showModal={showModal} setShowModal={setShowModal} />
        <UserEditModal />
        <div className='table'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button onClick={() => navigate('/user/user-add-form')} className='gred-button'>
              Add User
            </Button>
          </div>
          <Table
            dataSource={data && data.data}
            columns={columns}
            scroll={{ y: 400, x: 1000 }}
            bordered
            loading={isLoading}
          />
        </div>
      </MainLayout>
    </>
  );
};

export default ManageUser;
