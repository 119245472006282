//login Api
export const LOGIN = "/admin/api/v1/admin/login";

//manage user
export const USERS = "/admin/api/v1/users";

export const FIELDS = "/admin/api/v1/fields";
//manage user Provider
export const USERS_PROVIDER = "/admin/api/v1/providers";
export const LEVEL_TWO_PROVIDERS = "/admin/api/v1/providers/level-two";

//cms enms
export const ENMS_HEALTH = "/api/v1/enum/health";
export const ENMS_SPECIAL = "/api/v1/enum/specialization";
export const ENMS_QUALIFICATION = "/api/v1/enum/qualification";
export const ENMS_SKILL = "/api/v1/enum/skill";
export const ENMS_CHIROPOCTOR = "/api/v1/enum/chiropractor";
export const ENMS_THERAPY = "/api/v1/enum/therapy";
export const ENMS_LIFESTYLE = "/api/v1/enum/lifestyle";
export const CHRONIC = "/api/v1/enum/chronic-disease";
export const BOOKING = "/admin/api/v1/bookings";
export const PAYMENT = "/admin/api/v1/payments";
export const STATIC = "/admin/api/v1/static-pages";
export const TRANSACTION = "/admin/api/v1/transactions";
export const PAYOUT = "/admin/api/v1/payout";

// dashboard
export const DASHBOARD = "/admin/api/v1/get-count";
