import { Table } from "antd";

import MainLayout from "../../component/MainLayout";
import { useGetBookingQuery } from "../../redux/Api/adminApi";
import SearchBar from "../../component/SearchBar";
import { useSearch } from "../../hooks/useSearch";

const Reject = () => {
  const { data, isLoading } = useGetBookingQuery(3);
  const [rejectedBookings, setSearch] = useSearch(data);

  const columns = [
    {
      key: "1",
      title: "Id",
      dataIndex: "_id",
    },
    {
      key: "2",
      title: "Doctor Name",
      render: (record) => record.doctorId && record.doctorId.name,
    },
    {
      key: "3",
      title: "User Name",
      render: (record) => record.userId.name,
    },

    {
      key: "4",
      title: "Description",
      dataIndex: "description",
    },

    {
      key: "5",
      title: "Date",
      render: (record) =>
        new Date(record.selectedDateTime).toLocaleDateString(),
    },
  ];

  return (
    <MainLayout>
      <div className="table">
        <div className="search-bar">
          <SearchBar setSearch={setSearch} />
        </div>
        <Table
          dataSource={rejectedBookings}
          columns={columns}
          scroll={{ y: 450, x: 1000 }}
          bordered
          loading={isLoading}
        />
      </div>
    </MainLayout>
  );
};

export default Reject;
