import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isModalAdd: false,
  isModalEdit: false,
  editRecord: {},
};

export const healthSlice = createSlice({
  name: 'health',
  initialState,
  reducers: {
    showAddModalOpen: (state) => {
      state.isModalAdd = true;
    },

    showAddModalClose: (state) => {
      state.isModalAdd = false;
    },

    showEditModalOpen: (state, action) => {
      state.isModalEdit = true;
      state.editRecord = action.payload;
    },

    showEditModalClose: (state) => {
      state.isModalEdit = false;
    },

    editCondation: (state, action) => {
      state.editRecord = action.payload;
    },
  },
});

export const { showAddModalOpen, showAddModalClose, showEditModalOpen, showEditModalClose, editCondation } =
  healthSlice.actions;

export default healthSlice.reducer;
