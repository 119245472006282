import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Select, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useGetLevelTwoProvidersQuery, usePutUserProviderMutation } from '../../../redux/Api/adminApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'antd/es/form/Form';
import { showEditModalClose } from '../../../redux/slice/healthSlice';

const ProviderEditModal = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const { isModalEdit, editRecord } = useSelector((state) => state.health);
  const [putUserProvider, response] = usePutUserProviderMutation();
  const { data } = useGetLevelTwoProvidersQuery();
  const [form] = useForm();

  useEffect(() => {
    if (editRecord) {
      form.setFieldsValue({
        name: editRecord.name,
        level: editRecord.level,
        assignedProviders: editRecord.assignedProviders || [],
      });
    }
  }, [editRecord, form]);

  const onFinish = async (value) => {
    const fd = new FormData();
    fd.append('name', value.name);
    fd.append('level', value.level);
    if (value.assignedProviders) fd.append('assignedProviders', JSON.stringify(value.assignedProviders));
    if (file) fd.append('file', file);

    const data = {
      id: editRecord._id,
      type: fd,
    };
    putUserProvider(data);
  };

  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
      dispatch(showEditModalClose());
    } else if (response && response.error) {
      toast.error(response.error.data.message);
    }
  }, [response]);

  const levelObj = [
    { label: 'Level 1', value: 1 },
    { label: 'Level 2', value: 2 },
  ];

  const levelTwoProviders = data?.data;
  const handleCancel = () => {
    dispatch(showEditModalClose());
  };

  return (
    <>
      <Modal open={isModalEdit} title='Edit Provider' onCancel={handleCancel} footer={() => <></>}>
        <Form
          form={form}
          initialValues={{
            name: editRecord?.name,
            level: editRecord?.level,
            assignedProviders: editRecord?.assignedProviders,
          }}
          layout='vertical'
          onFinish={onFinish}
        >
          <Form.Item label='Name' name='name'>
            <Input />
          </Form.Item>
          <Form.Item label='Provider Level' name='level'>
            <Select options={levelObj} />
          </Form.Item>

          <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.level !== curValues.level} noStyle>
            {({ getFieldValue }) => {
              return getFieldValue('level') === 1 ? (
                <Form.Item label='Assign Level Two Providers' name='assignedProviders'>
                  <Select
                    placeholder='Select Providers'
                    showSearch
                    mode='multiple'
                    optionFilterProp='label'
                    options={levelTwoProviders}
                  />
                </Form.Item>
              ) : null;
            }}
          </Form.Item>

          <Form.Item label='Upload Image'>
            <Input type='file' onChange={(e) => setFile(e.target.files)} />
          </Form.Item>
          <Button type='primary' htmlType='submit' className='gred-button'>
            Edit User
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default ProviderEditModal;
