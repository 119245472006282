import React, { useEffect } from "react";

import { Table,Button } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import {toast} from "react-toastify"
import { useDispatch } from "react-redux";

import MainLayout from "../../../component/MainLayout"
import { showAddModalOpen, showEditModalOpen } from "../../../redux/slice/healthSlice";
import {  useDelLifestyleMutation, useDelTherapyMutation, useGetLifestyleQuery, useGetTherapyQuery } from "../../../redux/Api/adminApi";
import { LifestyleAddModal, LifestyleEditModal } from "./LifestyleModal";


const Lifestyle = () => {
  const { data,isLoading } = useGetLifestyleQuery();
  const [delSkill,response] = useDelLifestyleMutation();
  const dispatch = useDispatch();

  const columns = [
    {
      key: "1",
      title: "Id",
      dataIndex: "_id",
    },
    {
      key: "2",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: '3',
      title: 'Image',
      dataIndex: 'serviceimage',
      render: (record) => (
        <td style={{ backgroundColor: 'black', padding: '10px' }}>
          <img 
            width={50} 
            height={50} 
            style={{ borderRadius: '10px' }}
            src={record || 'path_to_default_image.jpg'} 
            alt="Image"
          />
        </td>
      )
    },
        // {
        //   key: "4",
        //   title: "Type",
        //   dataIndex: "type",
        // },
    {
      key: "5",
      title: "Action",
      render: (record) => {
        console.log(record,"record");
        return (
          <>
            <EditOutlined
              onClick={() => dispatch(showEditModalOpen(record))}
              style={{ color: "green", marginLeft: "20px" }}
            />
            <DeleteOutlined
              onClick={() => delSkill(record._id)}
              style={{ color: "red", marginLeft: "20px" }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if(response && response.data){
      toast.success( response.data.message)
    }
   },[response])
 

  return (
    <>
      <MainLayout>
        <div className="table">
        <Button
            className="gred-button"
            onClick={() => dispatch(showAddModalOpen())}
          >
            Add
          </Button>
          <Table
            dataSource={data&&data.list}
            columns={columns}
            scroll={{ y: 400, x: 1000 }}
            bordered
            loading={isLoading}
          />
        </div>
       <LifestyleEditModal/>
       <LifestyleAddModal/>
      </MainLayout>
    </>
  );
};

export default Lifestyle;
